import { mergeDeepLeft } from 'ramda';

import {
  FontSize as FontSizes, FontFamilies,
  ComponentGroups,
} from '@powdr/constants';

/** ***************************************** */
/* Default Font Overrides */
/** ***************************************** */
const FontSizeOverrides = {
  REG10: '12px',
  REG20: '14px',
  REG30: '15px',
  REG40: '17px',
  REG50: '19px',
  MED10: '21px',
  MED20: '23px',
  MED30: '25px',
  MED40: '27px',
  MED50: '29px',
  LRG10: '31px',
  LRG12: '33px',
  LRG20: '36px',
  LRG30: '42px',
  LRG40: '46px',
  LRG50: '50px',
};

const FontSize = mergeDeepLeft(FontSizeOverrides, FontSizes);

const ComponentFontAdjustments = {
  [ComponentGroups.HERO]: {
    title: '60px',
    'title-large-bp': '60px',
    'title-smaller-bp': '55px',
    copy: '26px',
    'copy-larger-bp': '30px',
    'copy-large-bp': '28px',
    'copy-smaller-bp': '22px',
  },
};

const fonts = {
  [FontFamilies.PRI_HDR_BLK]: 'font-family: "TradeGothicLT-BoldCondTwenty"',
  [FontFamilies.SEC_HDR_BLK]: 'font-family: "TradeGothicLT-BoldCondTwenty"',
  [FontFamilies.PRI_BDY_HVY]: 'font-family: "TradeGothicLT-BoldCondTwenty"',
  [FontFamilies.PRI_BDY_MED]: 'font-family: "TradeGothicLT-BoldCondTwenty";',
  boldExt: 'font-family: "Trade Gothic LT W01 Bold Ext"', // extra font just for UI image frame currently
};

export const fontTheme = {
  fonts,
  FontSize,
  ComponentFontAdjustments,
};
